// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://devapiglt.letsmobility.com',
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'ap-south-1:2fa418f8-8d49-4a92-887e-e83ecb54a685',
          Region: 'ap-south-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'glt-dev',
        Region: 'ap-south-1',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

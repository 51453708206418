import { Component } from '@angular/core';
import {
  NavigationCancel,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';

@Component({
  selector: 'glCrm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'glErpMain';

  constructor(private router: Router) {}
  loadingRouteConfig: boolean | undefined;
  ngOnInit(): void {
    this.loaderForMainModules();
  }

  loaderForMainModules() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
  }
}
